import * as React from "react";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Link,
  Pagination,
  Button,
  Typography,
  MenuItem,
  Menu,
  IconButton,
} from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MoreVert } from "@mui/icons-material";
import { useContext } from "react";
import {
  staffTableBox,
  staffTableHeaderCell,
  staffTableCellWide,
  staffTablePaper,
  staffTableSpan,
  staffTableCell,
  staffTableHeadCell,
  staffTableHeadCellActive,
  staffTableCellTeacher,
  setupStaff,
} from "./StaffTable.style";
import {
  tableAvatar,
  paginationContent,
  textCenter,
  pt30,
} from "./sharedStyles";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import Authorize from "./Authorize";
import RecordSubstitute from "./Staff/TemporaryStaff/RecordSubstitute";
import EnableAttendance from "./Staff/TemporaryStaff/EnableAttendance";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "NAME",
    sortable: true,
    align: "left",
  },
  {
    id: "position",
    numeric: false,
    disablePadding: true,
    label: "POSITION",
    sortable: true,
    align: "left",
  },
  {
    id: "staff_type",
    numeric: false,
    disablePadding: true,
    label: "TYPE",
    sortable: true,
    align: "left",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: "ROLE",
    sortable: true,
    align: "left",
  },
  {
    id: "classes",
    numeric: false,
    disablePadding: true,
    label: "CLASSES",
    sortable: false,
    align: "left",
  },
  {
    id: "staffs",
    numeric: false,
    disablePadding: true,
    label: "STUDENTS",
    sortable: false,
    align: "left",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    sortable: true,
    align: "center",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const isHeaderActive = (id, sortable) =>
    orderBy === id
      ? staffTableHeadCellActive(sortable)
      : staffTableHeadCell(sortable);

  const renderSortChevron = (id) => {
    if (id !== orderBy) return "";

    return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={staffTableHeadCell(false)} />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ borderBottom: "none" }}
          >
            <Box
              {...(headCell.sortable && {
                onClick: createSortHandler(headCell.id),
              })}
              sx={isHeaderActive(headCell.id, headCell.sortable)}
              margin={headCell.align === "center" ? "0 auto" : ""}
            >
              {headCell.label}
              {renderSortChevron(headCell.id)}
            </Box>
          </TableCell>
        ))}
        <TableCell width="2.5%" />
      </TableRow>
    </TableHead>
  );
}

export default function StaffTable({
  isCompact,
  staffs,
  searchParams,
  setSearchParams,
  selected,
  order,
  orderBy,
  setOrderBy,
  setOrder,
  setSelected,
  setPage,
  totalPages,
  page,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentStaff, setCurrentStaff] = React.useState();
  const [recordSubstitueOpen, setRecordSubstituteOpen] = React.useState(false);
  const [enableAttendanceOpen, setEnableAttendanceOpen] = React.useState(false);
  const contextMenuOpen = Boolean(anchorEl);
  // eslint-disable-next-line no-unused-vars
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const params = useParams();
  const schoolId = params.school_id;
  const { hasAnyPermissionType } = useContext(PermissionsContext);

  const handleClick = (event, staff) => {
    setAnchorEl(event.currentTarget);
    setCurrentStaff(staff);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const managePermission = hasAnyPermissionType(APP_PERMISSIONS.STAFF_ACCOUNT);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = staffs.map((staff) => staff.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
    setSelected([]);
  };

  function handleProfilePictureSrc(staff, cursor = "pointer") {
    if (staff?.image_url) {
      return (
        <img
          alt="profile_image"
          src={staff.image_url.url}
          style={{
            borderRadius: "100px",
            width: "40px",
            height: "40px",
            objectFit: "cover",
          }}
        />
      );
    }
    return (
      <Avatar sx={{ ...tableAvatar, cursor }}>
        {staff.first_name.charAt(0).toUpperCase()}
      </Avatar>
    );
  }

  const capitalize = (type) => {
    if (type === "support_staff") {
      return "Staff";
    }
    return (type && type[0].toUpperCase() + type.slice(1)) || "";
  };

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.STAFF_LIST)}>
      <Box sx={staffTableBox}>
        <Container maxWidth="lg">
          {staffs.length > 0 ? (
            <Paper sx={staffTablePaper} elevation={0}>
              <TableContainer>
                <Table aria-labelledby="tableTitle" size="medium">
                  {!isCompact && (
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={staffs.length}
                    />
                  )}
                  <TableBody>
                    {staffs.map((staff) => {
                      const labelId = `enhanced-table-checkbox-${staff.id}`;

                      return (
                        <TableRow hover tabIndex={-1} key={staff.id}>
                          {managePermission ? (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={staffTableHeaderCell(isCompact)}
                            >
                              <Link
                                to={`/school/${schoolId}/staff/${staff.id}/account`}
                                underline="none"
                                component={RouterLink}
                              >
                                {handleProfilePictureSrc(staff)}
                              </Link>
                            </TableCell>
                          ) : (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={staffTableHeaderCell(isCompact)}
                            >
                              {handleProfilePictureSrc(staff, "default")}
                            </TableCell>
                          )}
                          {managePermission ? (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={staffTableCellWide(isCompact)}
                            >
                              <Link
                                to={`/school/${schoolId}/staff/${staff.id}/account`}
                                underline="none"
                                component={RouterLink}
                              >
                                <span style={staffTableSpan(isCompact, true)}>
                                  {`${staff.first_name} ${staff.last_name}`}
                                </span>
                              </Link>
                            </TableCell>
                          ) : (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={staffTableCellWide(isCompact)}
                            >
                              <span style={staffTableSpan(isCompact)}>
                                {`${staff.first_name} ${staff.last_name}`}
                              </span>
                            </TableCell>
                          )}
                          {!isCompact && (
                            <>
                              <TableCell
                                align="center"
                                sx={staffTableCellTeacher}
                              >
                                {staff.employments.find(
                                  (employment) =>
                                    employment.school_slug === schoolId
                                )?.position || "-"}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={staffTableCellTeacher}
                              >
                                {capitalize(staff.staff_type)}
                              </TableCell>
                              <TableCell align="center" sx={staffTableCell}>
                                -
                              </TableCell>
                              <TableCell align="center" sx={staffTableCell}>
                                {staff.klasses_count || "-"}
                              </TableCell>
                              <TableCell align="center" sx={staffTableCell}>
                                {staff.students_count || "-"}
                              </TableCell>
                              <TableCell
                                width="5%"
                                sx={staffTableCell}
                                style={{ textAlign: "center" }}
                              >
                                {staff.is_new ? (
                                  <Link
                                    to={`/school/${schoolId}/staff/${staff.id}/account`}
                                    underline="none"
                                    component={RouterLink}
                                  >
                                    <Button sx={setupStaff}>
                                      Set Up Staff
                                    </Button>
                                  </Link>
                                ) : (
                                  (staff.employments.find(
                                    (employment) =>
                                      employment.school_slug === schoolId
                                  )?.active &&
                                    "Active") ||
                                  "Inactive"
                                )}
                              </TableCell>
                              {managePermission && (
                                <TableCell>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      contextMenuOpen ? "long-menu" : undefined
                                    }
                                    aria-expanded={
                                      contextMenuOpen ? "true" : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={(e) => handleClick(e, staff)}
                                  >
                                    <MoreVert />
                                  </IconButton>
                                </TableCell>
                              )}
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={contextMenuOpen}
                onClose={handleClose}
              >
                {currentStaff?.is_active
                  ? [
                      <MenuItem
                        key="record-substitution"
                        onClick={() => {
                          setRecordSubstituteOpen(true);
                          handleClose();
                        }}
                      >
                        Record Substitution
                      </MenuItem>,
                      <MenuItem
                        key="enable-attendance"
                        onClick={() => {
                          setEnableAttendanceOpen(true);
                          handleClose();
                        }}
                      >
                        Enable Attendance
                      </MenuItem>,
                    ]
                  : null}
              </Menu>
              {!isCompact && (
                <Pagination
                  count={totalPages}
                  onChange={handleChangePage}
                  sx={paginationContent}
                  page={parseInt(page, 10)}
                />
              )}
            </Paper>
          ) : (
            <Typography sx={[textCenter, pt30]}>No staff found.</Typography>
          )}
          {recordSubstitueOpen && (
            <RecordSubstitute
              open={recordSubstitueOpen}
              substituteId={currentStaff?.id}
              setRecordSubstituteOpen={setRecordSubstituteOpen}
              isTemporary={false}
            />
          )}
          {enableAttendanceOpen && (
            <EnableAttendance
              open={enableAttendanceOpen}
              teacherID={currentStaff?.id}
              setEnableAttendanceOpen={setEnableAttendanceOpen}
            />
          )}
        </Container>
      </Box>
    </Authorize>
  );
}
